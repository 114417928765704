<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Workflow Document Version" icon="code-branch" />

    <div class="mainBox mainBox62 border shadow-sm">
        <div v-if="doc" class="row">
            <div class="col-12">
                <span>
                    <Popper class="popperDark" arrow hover content="Back to Workflow Document">
                        <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/workflow/version/list' })" />
                    </Popper>
                </span>
                <span class="fw-bold">{{docName}}</span>
                
                <span v-if="toggleDocBox" class="float-end px-2 isLink" @click="toggleDocBox = false"><fa icon="angle-up" /></span>
                <span v-if="!toggleDocBox" class="float-end px-2 isLink" @click="toggleDocBox = true"><fa icon="angle-down" /></span>
            </div>
        </div>

        <div v-if="toggleDocBox && doc" class="row mt-2">
            <div class="col-lg-6 d-grid">
                <div class="innerBox">
                    <div class="mb-2">
                        <span class="innerBoxTitle">DOCUMENT</span>
                        <span class="float-end fst-italic small isTooltipsLabel">
                            <Popper class="popperDark" arrow hover content="Document status">{{doc.status}}</Popper>
                        </span>
                    </div>
                    <table border="0">
                        <tr>
                            <td class="pe-3"><Avatar isUser="false" avatar="" displayName="" isVerified="doc.creator.isVerified" width="60" height="60" /></td>
                            <td class="small">
                                <div>Created by <span class="fw-bold text-grey">{{doc.creator.displayName}}</span></div>
                                <div class="mb-2">{{doc.creator.userId}}</div>
                                <div>Created at {{ func.convDateTimeFormat(doc.dateCreate, 'datetime') }}</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="col-lg-6 d-grid">
                <div class="innerBox">
                    <div class="mb-2">
                        <span class="innerBoxTitle">WORKFLOW</span>
                        <span class="float-end fst-italic small isTooltipsLabel">
                            <Popper class="popperDark" arrow hover content="Current workflow document status">{{doc.workflow.workflowStatus}}</Popper>
                        </span>
                    </div>
                    <table width="100%" class="small">
                        <tr>
                            <td>Applied <span class="fw-bold text-grey">{{doc.workflow.workflowName}}</span></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="progress mt-3"> 
                                    <div class="progress-bar progress-bar-striped" :style="'width: ' + Math.ceil(doc.workflow.totalStepCompleted / doc.workflow.totalStep * 100) + '%'">{{ Math.ceil(doc.workflow.totalStepCompleted / doc.workflow.totalStep * 100) }}% </div>
                                </div>
                                <div class="text-center small">{{doc.workflow.totalStepCompleted}} / {{doc.workflow.totalStep}} steps</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </div>        

    <div class="nextBox nextBox62 border shadow-sm">
        <div v-if="doc" class="row">
            <div class="col-lg-6 mb-2 fw-bold">Version {{docVersion}} <span v-if="docVersion === docVersionLatest" class="text-grey ms-1 fw-normal fst-italic">latest</span></div>
            <div class="col-lg-6 mb-2">
                <table width="150px" border="0" align="right">
                    <tr>
                        <td class="text-center" width="25%">
                            <span class="px-2" :class="docVersion === 1 ? 'text-grey' : 'isLink'" @click="changeVersion('first')" :disabled="docVersion === 1">
                                <fa icon="angle-double-left" />
                            </span>
                        </td>
                        <td class="text-center" width="25%">
                            <span class="px-2" :class="docVersion === 1 ? 'text-grey' : 'isLink'" @click="changeVersion('previous')" :disabled="docVersion === 1">
                                <fa icon="angle-left" />
                            </span>
                        </td>
                        <td class="text-center" width="25%">
                            <span class="px-2" :class="docVersion === docVersionLatest ? 'text-grey' : 'isLink'"  @click="changeVersion('next')" :disabled="docVersion === docVersionLatest">
                                <fa icon="angle-right" />
                            </span>
                        </td>
                        <td class="text-center" width="25%">
                            <span class="px-2" :class="docVersion === docVersionLatest ? 'text-grey' : 'isLink'" @click="changeVersion('last')" :disabled="docVersion === docVersionLatest">
                                <fa icon="angle-double-right" />
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="doc.workflow.versions && docVersion !== 0" class="col-lg-6 mb-2">Updated at {{func.convDateTimeFormat(doc.workflow.versions[docVersion-1].timestamp, 'datetime')}}</div>
            <div v-if="doc.workflow.versions && docVersion !== 0" class="col-lg-6 mb-3 text-end fst-italic">{{doc.workflow.versions[docVersion-1].status}}</div>
        </div>

        <div v-if="doc && doc.workflow.versions" class="row px-2">
            <div class="col-lg-12 mb-4 innerBox">
                <div v-for="(step, stepIdx) in doc.workflow.versions[docVersion-1].steps" :key="step">
                    <div v-for="item in step" :key="item">
                        <table class="table table-hover mb-0" border="0">
                            <tbody>
                                <tr>
                                    <td width="70px" class="small">STEP {{stepIdx+1}}</td>
                                    <td>
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <table>
                                                    <tr>
                                                        <td width="30px">
                                                            <span v-if="doc.workflow.versions[docVersion-1].timestamp === item.timestamp">
                                                                <fa icon="play" class="text-primary" />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.isRole" class="badge bg-grey">{{item.name}}</span>
                                                            <span v-else>{{item.name}}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="col-lg-2 small">
                                                <span v-if="item.timestamp !== ''">{{func.convDateTimeFormat(item.timestamp, 'datetime')}}</span>
                                            </div>
                                            <div class="col-lg-2 small">
                                                <WorkflowStepStatus :status="item.status" />
                                            </div>
                                            <div class="col-lg-4 small">
                                                <div v-if="item.statusDesc">{{item.statusDesc}}</div>
                                                <div v-if="item.attachments && item.attachments.length > 0">
                                                    <div v-for="a in item.attachments" :key="a">
                                                        <fa icon="paperclip" class="me-2" />{{a.name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-12 text-end">
                <span v-if="toggleLegend" class="isLink" @click="toggleLegend = false">
                    <fa icon="angle-up" class="me-2" />Legend
                </span>
                <span v-if="!toggleLegend" class="isLink" @click="toggleLegend = true">
                    <fa icon="angle-down" class="me-2" />Legend
                </span>
            </div>

            <div v-if="toggleLegend" class="col-12">
                <div><hr /></div>
                <table class="table table-sm table-borderless">
                    <tbody>
                        <tr>
                            <td width="90px"><WorkflowStepStatus status="PENDING" /></td>
                            <td>Pending for other signee to take action (i.e. sign or reject)</td>
                        </tr>
                        <tr>
                            <td><WorkflowStepStatus status="ACTION" /></td>
                            <td>Pending for signee to take action</td>
                        </tr>
                        <tr>
                            <td><WorkflowStepStatus status="DISABLED" /></td>
                            <td>No action required as action already took by another alternative signee</td>
                        </tr>
                        <tr>
                            <td><WorkflowStepStatus status="APPROVED" /></td>
                            <td>Signed by the signee</td>
                        </tr>
                        <tr>
                            <td><WorkflowStepStatus status="REJECTED" /></td>
                            <td>Rejected by the signee</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    

</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'
import WorkflowStepStatus from '@/components/WorkflowStepStatus.vue'

export default {
    name: 'WorkFlowVersion',
    components: { Alert, TopNavigation, Popper, Avatar, WorkflowStepStatus },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const toggleDocBox = ref(true)
        const toggleLegend = ref(false)

        const doc = ref(null)
        const docName = ref(null)
        const docVersion = ref(0)
        const docVersionLatest = ref(1)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const changeVersion = (opt) => {
            if (opt === 'first') {
                docVersion.value = 1
            } else if (opt === 'previous') {
                docVersion.value = docVersion.value - 1

            } else if (opt === 'next') {
                docVersion.value = docVersion.value + 1
                
            } else if (opt === 'last') {
                docVersion.value = docVersionLatest.value
            }

            router.push({ path: '/workflow/version/' + route.params.documentId, query: { v: docVersion.value } })
        }

        const getDoc = () => {

            /* Need 3 status:-
              1. worflowStatus - current workflow status
              2. versions > status - workflow status in the respective version
              3. steps status:
                  PENDING - Not your turn yet 
                  DISABLED - No signed required as sibling already signed 
                  ACTION - Pending for your action
                  APPROVED - Signed
                  REJECTED - Rejected
            */

            doc.value = {
                projectId: 'gXpKC-2Wr7jEftsJF5Uxg',
                documentId: 'gXBxW-2Wr7jEftsJF5Uxg',
                dateModify: '2022-07-08T09:09:12.000Z',
                dateCreate: '2022-07-04T07:36:33.000Z',
                dateComplete: '0',
                creator: {
                    displayName: 'Y.N Lim',
                    avatar: "https://lh3.googleusercontent.com/a/AATXAJy75Od2bWhR_ZCFPKNSYe9HMOHENV6QEJcMGd1K=s96-c",
                    isVerified: false,
                    userId: 'yennee.lim@genusis.com'
                },
                status: 'SIGNING',
                isSort: true,
                privacy: 'CONFIDENTIAL',
                remark: 'please sign ASAP',
                note: 'this is the note for creator only',
                file: {
                    size: 80656,
                    name: 'D_202218.pdf',
                    mimetype: 'application/pdf',
                    encoding: '7bit',
                    totalPage: 5,
                    pageWidth: 595.303937007874,
                    pageHeight: 841.889763779528
                },
                workflow: {
                    workflowId: '12345',
                    workflowName: 'Deployment Flow',
                    workflowStatus: 'REJECTED',
                    progress: 60,
                    totalStep: 5,
                    totalStepCompleted: 3,
                    versions: [
                        {
                            version: 1,
                            timestamp: '2022-07-10T15:37:03.000Z',
                            status: 'ONGOING',
                            steps: [
                                [ 
                                    { isRole: true, signeeId: 'Developer', name: 'Developer', email: '', node: 'node1', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-10T15:37:03.000Z' } 
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'Support', name: 'Support', email: '', node: 'node2', status: 'ACTION', attachments: [], statusDesc: '', timestamp: '' }, 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node4', status: 'ACTION', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'CS', name: 'CS', email: '', node: 'node3', status: 'PENDING', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: 'DED17C76E24F11EC89D6FB54B2801234', name: 'Enerson', email: 'enerson.yap@genusis.com', node: 'node5', status: 'PENDING', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node6', status: 'PENDING', attachments: [], statusDesc: '', timestamp: '' } 
                                ]
                            ]
                        },
                        {
                            version: 2,
                            timestamp: '2022-07-11T01:03:00.000Z',
                            status: 'ONGOING',
                            steps: [
                                [ 
                                    { isRole: true, signeeId: 'Developer', name: 'Developer', email: '', node: 'node1', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-10T15:37:03.000Z' } 
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'Support', name: 'Support', email: '', node: 'node2', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-11T01:03:00.000Z' }, 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node4', status: 'DISABLED', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'CS', name: 'CS', email: '', node: 'node3', status: 'ACTION', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: 'DED17C76E24F11EC89D6FB54B2801234', name: 'Enerson', email: 'enerson.yap@genusis.com', node: 'node5', status: 'PENDING', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node6', status: 'PENDING', attachments: [], statusDesc: '', timestamp: '' } 
                                ]
                            ]
                        },
                        {
                            version: 3,
                            timestamp: '2022-07-12T02:15:00.000Z',
                            status: 'ONGOING',
                            steps: [
                                [ 
                                    { isRole: true, signeeId: 'Developer', name: 'Developer', email: '', node: 'node1', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-10T15:37:03.000Z' } 
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'Support', name: 'Support', email: '', node: 'node2', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-11T01:03:00.000Z' }, 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node4', status: 'DISABLED', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'CS', name: 'CS', email: '', node: 'node3', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-12T02:15:00.000Z' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: 'DED17C76E24F11EC89D6FB54B2801234', name: 'Enerson', email: 'enerson.yap@genusis.com', node: 'node5', status: 'ACTION', attachments: [], statusDesc: '', timestamp: '' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node6', status: 'PENDING', attachments: [], statusDesc: '', timestamp: '' } 
                                ]
                            ]
                        },
                        {
                            version: 4,
                            timestamp: '2022-07-12T08:00:00.000Z',
                            status: 'REJECTED',
                            steps: [
                                [ 
                                    { isRole: true, signeeId: 'Developer', name: 'Developer', email: '', node: 'node1', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-10T15:37:03.000Z' } 
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'Support', name: 'Support', email: '', node: 'node2', status: 'APPROVED', attachments: [], statusDesc: '', timestamp: '2022-07-11T01:03:00.000Z' }, 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node4', status: 'DISABLED', attachments: [], statusDesc: '', timestamp: '' }
                                ], 
                                [ 
                                    { isRole: true, signeeId: 'CS', name: 'CS', email: '', node: 'node3', status: 'APPROVED', attachments: [], statusDesc: '',  timestamp: '2022-07-12T02:15:00.000Z' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: 'DED17C76E24F11EC89D6FB54B2801234', name: 'Enerson', email: 'enerson.yap@genusis.com', node: 'node5', status: 'REJECTED', attachments: [ { path: '/xxx/xxx/xxx', name: 'screenshot-1.jpg'}, { path: '/xxx/xxx/xxx', name: 'user_interface_route.png'} ], statusDesc: 'Change the route limit from 8 to unlimited', timestamp: '2022-07-12T08:00:00.000Z' } 
                                ], 
                                [ 
                                    { isRole: false, signeeId: '6E7A09F8E24E11EC89D6FB54B2806B93', name: 'Arshad', email: 'arshad@genusis.com', node: 'node6', status: 'ACTION', attachments: [], statusDesc: '', timestamp: '' } 
                                ]
                            ]
                        },
                    ]
                }
            }
            docName.value = doc.value.file.name.replace('.pdf', '')

            // Show latest version by default
            let vers = doc.value.workflow.versions.length * 1 - 1
            if (route.query.v) {
                docVersion.value = route.query.v * 1
            } else {
                docVersion.value = doc.value.workflow.versions[vers].version
            }
            docVersionLatest.value = doc.value.workflow.versions[vers].version
            console.info('getDoc docVersion', docVersion.value, typeof docVersion.value)
        }

        onMounted( () => {
            func.clearPrevLocalStorage()
            getDoc()

        })

        return { 
            alert, closeAlert, router, route, func, store, 
            toggleDocBox, toggleLegend, 
            doc, docName, docVersion, docVersionLatest, changeVersion, 
        }
    }
}
</script>

<style>
body {
  overflow: scroll;
}

.innerBox {
    border-radius: 10px;
    padding: 20px;
    background-color: var(--auth-box-bg);
}

.innerBoxTitle {
    font-size: 12px;
    font-weight: 700 !important;
    margin-bottom: 0.5rem !important;
    box-sizing: border-box;
    color: #6E777E;
    line-height: var(--bs-body-line-height);
}
</style>